
<template>
        <div>
            <div v-if="this.currentMaintainer !== null" class="row">
                <div class="col-md-3">
                    <div v-if="!editMode">
                        <div class="box box-widget widget-user-2">
                            <div class="widget-user-header bg-yellow-active">
                                <div class="widget-user-image">
                                    <img class="img-circle" src="../assets/img/user1-128x128.jpg" alt="User Avatar">
                                </div>
                                <h3 class="widget-user-username">{{currentMaintainer.fullname}}</h3>
                                <h5 class="widget-user-desc">
                                    <span @click="makeCurrentCus(currentMaintainer.customerid)" style="cursor: pointer">{{currentMaintainer.company_name}}</span>
                                </h5>
                            </div>
                        </div>
                        <div class="box box-widget">
                            <ul class="nav nav-stacked info-rows"> 
                                <li>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span class="text-muted" style="line-height: 3.4rem; white-space: nowrap">Evidováno {{$helpers.getDate(currentMaintainer.registered)}}</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <button @click="editMaintainer()" type="button" class="btn btn-default pull-right"><span><i class="fa fa-edit"></i> Upravit</span></button>    
                                        </div>
                                    </div>
                                </li>
                                <li v-if="(currentMaintainer.iscontact || currentMaintainer.isinvoice || currentMaintainer.admin || currentMaintainer.cars)">
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span>Role</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="pull-right">
                                                <span class="badge bg-light-blue" v-if="currentMaintainer.iscontact">Kontaktní osoba</span>
                                                <span class="badge bg-green" v-if="currentMaintainer.isinvoice">Pověřen fakturací</span>
                                                <span class="badge bg-red" v-if="currentMaintainer.admin" style="cursor: pointer" v-tooltip="'Toto umožňuje uživateli vidět všechna auta a záznamy jeho společnosti.'">Správce</span>
                                                <span class="badge" v-if="currentMaintainer.cars">Řidič</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span>Email</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="pull-right">
                                                <span class="ml-auto">{{currentMaintainer.email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="currentMaintainer.phone">
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span>Telefon</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="pull-right">
                                                <span class="ml-auto">{{currentMaintainer.phone}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="currentMaintainer.login">
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span style="white-space: nowrap">Uživatelské jméno</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="pull-right">
                                                <span class="ml-auto">{{currentMaintainer.login}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <p v-if="currentMaintainer.login && currentMaintainer.canlog" class="help-block"><i class="fa fa-unlock"></i> Tato osoba má povolený přístup k servisní knize.</p>
                                            <p v-else-if="currentMaintainer.login" class="help-block"><i class="fa fa-lock"></i> Tato osoba nemá povolený přístup k servisní knize.</p>
                                            <p v-else><i class="fa fa-lock"></i> Tato osoba zatím nemá přístup k servisní knize.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else>
                        <div class="box box-widget widget-user-2">
                            <div class="widget-user-header form-group">
                                <input name="full_name" id="inputName" class="form-control input-lg"  v-model="$v.formResponses.fullname.$model" type="text">
                                <span v-if="!$v.formResponses.fullname.required" class="help-block">Povinný údaj</span>
                                <span v-if="!$v.formResponses.fullname.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                            </div>
                        </div>
                        <div class="box box-widget">
                            <ul class="nav nav-stacked info-rows"> 
                                <li>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span>Email</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="form-group" style="margin: 0" :class="{'has-error': $v.formResponses.email.$error}">
                                                <input name="email" type="email" class="form-control input-sm" v-model="$v.formResponses.email.$model">
                                                <span v-if="!$v.formResponses.email.required" class="help-block">Povinný údaj</span>
                                                <span v-if="!$v.formResponses.email.email" class="help-block">Musí mít formát emailové adresy!</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <span>Telefon</span>
                                        </div>
                                        <div class="col-xs-8">
                                            <div class="form-group" style="margin: 0" :class="{'has-error': $v.formResponses.phone.$error}">
                                                <input name="email" type="email" class="form-control input-sm" v-model="$v.formResponses.phone.$model">
                                                <span v-if="!$v.formResponses.phone.phone_format" class="help-block">Musí mít formát telefonního čísla!</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-8">
                                            <span v-if="currentMaintainer.login">Povolit přihlášení</span>
                                            <span v-else>Vytvořit a poslat přihlašovací údaje na email</span>   
                                        </div>
                                        <div class="col-xs-4">     
                                            <input type="checkbox" class="pull-right" v-model="canlog">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-8">
                                            <span>Správcovský přístup <i class="fa fa-question-circle" v-tooltip="'Toto umožňuje uživateli vidět všechna auta a záznamy jeho společnosti.'"></i></span>
                                        </div>
                                        <div class="col-xs-4">     
                                            <input type="checkbox" class="pull-right" v-model="admin" :disabled="!canlog">
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <button type="button" class="btn btn-success" @click="mntUpdate()" :disabled="$v.formResponses.$invalid">Uložit změny</button> <br>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <button type="button" class="btn btn-danger btn-sm" @click="mntDelete()">Odstranit záznam</button>
                                        </div>
                                        <div class="col-xs-6">
                                             <button type="button" class="btn btn-default btn-sm pull-right" @click="editMode = false">Zrušit úpravy</button>
                                        </div>
                                    </div>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="nav-tabs-custom">
                        <ul class="nav nav-tabs">
                            <li class="active"><a href="#cars_sub" data-toggle="tab"><i class="fa fa-car"></i> Auta</a></li>
                            <li v-if="!user.cus"><a href="#issues_sub" data-toggle="tab"><i class="fa fa-tasks"></i> Úkony</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="active tab-pane" id="cars_sub">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="input-group input-group-sm" style="width: 100%">
                                            <input v-model="filterCars" type="text" name="table_search_cars" class="form-control" placeholder="Hledat">
                                        </div>
                                    </div>
                                    <div v-if="!user.cus" class="col-lg-8">
                                        <router-link :to="{ name: 'CarAdd', params: { maintainerid: currentMaintainer.maintainerid, customerid: currentMaintainer.customerid } }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat auto</router-link>
                                    </div>
                                </div>
                                <br>
                                <div v-if="allCars.length" class="table-responsive no-padding" style="width: auto; overflow: auto">
                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th style="width: 36px"></th>
                                            <th>Název</th>
                                            <th>Poslední úkon</th>
                                            <th>Následující úkon</th>
                                            <th>VIN</th>
                                            <th>SPZ</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="car in getCars" :key="car.carid">
                                                <td><span class="label" :style="`border: solid 1px #EEEFF4;background-color: ${car.color}; color: ${car.color}`">_</span></td>
                                                <td style="cursor: pointer" @click="makeCurrentCar(car.carid)"><a>{{car.name}}</a></td>
                                                <td>{{car.last_issue}}</td>
                                                <td style="cursor: pointer" @click="makeCurrentIssue(car.next_issueid)"><a>{{car.next_issue}}</a></td>
                                                <td>{{car.vin_code}}</td>
                                                <td>{{car.licence_plate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else class="callout callout-info">
                                    <p><i class="icon fa fa-info"></i> U tohoto zákazníka nejsou evidována žádná auta.</p>                          
                                </div>
                            </div>
                            <div class="tab-pane" id="issues_sub">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="input-group input-group-sm" style="width: 100%">
                                            <input v-model="filterIssues" type="text" name="table_search_issues" class="form-control" placeholder="Hledat">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="input-group input-group-sm" style="width: 100%">   
                                            <button style="width: 25%" type="button" class="btn btn-default btn-flat btn-sm" :class="{'active btn-primary': showByStage == issueType.id}" v-for="issueType in this.issueTypes" :key="issueType.id" @click="showByStage = issueType.id">{{issueType.name}}</button>       
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <router-link :to="{ name: 'IssueAdd', params: { customerid: currentMaintainer.customerid, maintainerid: currentMaintainer.maintainerid } }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat úkon</router-link>
                                    </div>
                                </div>
                                <br>
                                <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                                    <table class="table table-hover">
                                        <thead>
                                        <tr>
                                            <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="issue in getIssues" :key="issue.issueid">
                                                <td><span class="label" :style="`background-color: ${issue.tag_color}`">{{issue.tag_title}}</span></td>
                                                <td style="cursor: pointer" @click="makeCurrentIssue(issue.issueid)"><a>{{issue.name}}</a></td>
                                                <td>{{fixCorruptedTime(issue.due_from)}}</td>
                                                <td>{{fixCorruptedTime(issue.due_to)}}</td>
                                                <td style="cursor: pointer" @click="makeCurrentCar(issue.carid)"><a>{{issue.car_name}}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
const name = 'MaintainerDetail';

const ISSUE_TYPES = {
    VSECHNO: 0,
    PLANOVANO: 1,
    OTEVRENO: 4,
    UZAVRENO: 3,
}

import { mapGetters, mapActions } from "vuex";

import router from '../router';

// Vuelidate
import { required,  maxLength, email, helpers, requiredIf } from 'vuelidate/lib/validators'

const phone_format = helpers.regex('phone_format', /^[+]?[()/0-9. -]{9,}$/)

export default {
    name,
    data() {
        return {
            page: null,

            canlog: null,
            admin: null,

            formResponses: {
                fullname: '',
                email: '',
                phone: ''
            },

            editMode: false,
            showByStage: 0,
            filterIssues: '',
            filterCars: '',
            allCars: [],
            allIssues: [],
        }
    },
    validations: {
        formResponses: {
            fullname: {
                required,
                maxLength: maxLength(32)
            },
            email: {
                email,
                required: requiredIf(function() {
                    return this.canlog
                })
            },
            phone: {
                phone_format
            }
        }
    },
    computed: {
        ...mapGetters(["getAllPages", 'currentMaintainer', 'currentMaintainerId', 'user']),
        getCars() {
            var maintainerCars = this.allCars

            if (!maintainerCars?.length)
            {
                return []
            }

            // Filtr provedeme na vícero položkách auta -> možnost vyhledávání jak podle jména, tak podle VIN, atd..
            return maintainerCars.filter(item => {
                return (
                    this.$helpers.filterCheck(this.filterCars, item.name)
                )
            })
        },
        getIssues() {
            var allIssues = this.allIssues

            if (!allIssues?.length)
            {
                return []
            }
            
            // Filtr provedeme na vícero položkách, zároveň filtrujeme podle typu úkonu (Plánováno, atd.)
            allIssues.sort(function compare(a, b) {
                if(a.tagid != 3) {
                    if (a.tagid < b.tagid)
                        return -1;
                    if (a.tagid > b.tagid)
                        return 1;
                }
                return 0;
            }).sort(function compare(a, b) {
                if( (a.tagid == 3 || a.tagid == 4) && (b.tagid != 1) ) {
                    if (a.tagid < b.tagid)
                        return 1;
                    if (a.tagid > b.tagid)
                        return -1;
                }
                return 0;
            });
            return allIssues.filter(item => {
                return (
                    (this.showByStage === item.tagid || this.showByStage == ISSUE_TYPES.VSECHNO)
                    && (this.$helpers.filterCheck(this.filterIssues, item.name)
                        || this.$helpers.filterCheck(this.filterIssues, item.car_name)
                        )
                );
            })
        },
    }, 
    methods: {
        ...mapActions([
            'makeActiveTab', 'addTab', 'closeTab',
            'fetchMaintainer', 'updateMaintainer', 'deleteMaintainer',
            'makeCurrentCar', 'makeCurrentIssue', 'makeCurrentCus'
        ]),
        mntUpdate() {
            if (this.$v.formResponses.$invalid) 
                return 0;

            this.updateMaintainer({
                maintainerid: this.currentMaintainerId,
                fullname: this.formResponses.fullname,
                email: this.formResponses.email,
                phone: this.formResponses.phone,
                canlog: this.canlog,
                admin: this.admin
            }).then(() => {
                this.$alerts.toast('Změny úspěšně zapsány', 'success')
                this.editMode = false;
                this.fetchMaintainer(this.currentMaintainerId)
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'))
        },

        // Calls api for deleting the record
        mntDelete() {
            if (this.currentMaintainer.iscontact){
                this.$alerts.message('Nelze smazat kontaktní osobu!', 'Pokud ji chcete smazat, je třeba nejdříve zvolit jinou kontaktní osobu v nastavení zákazníka.', 'error');
                return 0;
            }

            let warningText = this.currentMaintainer.cars ? 'Jeho stávající auta budou bez řidiče. ' : '';
            warningText += this.currentMaintainer.isinvoice ? 'Jeho společnost bude bez fakturační osoby. ' : '';

            this.$alerts.confirmDelete('Opravdu chcete tento záznam smazat?', warningText).then((result) => {
                if (result.value) {
                    this.deleteMaintainer(this.currentMaintainerId).then(() => {
                        this.closeTab(this.page.id);
                        this.$alerts.message('Smazáno', '', 'success')
                    });
                }
            });

        },

        editMaintainer() {
            // Set values for update
            this.formResponses.fullname = this.currentMaintainer.fullname;
            this.formResponses.email = this.currentMaintainer.email;
            this.formResponses.phone = this.currentMaintainer.phone;

            this.editMode = true;
        },

        fixCorruptedTime(t) {
            let date = t.split("  ")
            let time = date[1].split(":")
            if (parseInt(time[1]) < 10) {
                time[1] = "0" + time[1]
            }
            return `${date[0]} ${time[0]}:${time[1]}`
        },
    },
    created() {
        if (this.currentMaintainerId)
        {
            this.fetchMaintainer(this.currentMaintainerId).then(() => {
                this.canlog = this.currentMaintainer.canlog;    
                this.admin = this.currentMaintainer.admin;
                this.allCars = this.currentMaintainer.cars
                this.allIssues = this.currentMaintainer.issues

                console.log(this.currentMaintainer)

                this.allIssues.forEach((issue) => {
                    issue.due_from = this.$helpers.getTimeAndDate(issue.due_from);
                    issue.due_to = this.$helpers.getTimeAndDate(issue.due_to);
                    issue.added = this.$helpers.getTimeAndDate(issue.added);
                });

                console.log(this.currentMaintainer)

                this.page = this.getAllPages.filter(page => page.name == name)[0]   
                this.addTab({...this.page, lng_name: this.currentMaintainer.fullname});
                this.makeActiveTab(this.page);
            });

            this.ISSUE_TYPES = ISSUE_TYPES
            this.headColumns = [
                { name: 'Stav' },
                { name: 'Název' },
                { name: 'Od' },
                { name: 'Do' },
                { name: 'Auto' }
            ]
            this.issueTypes = [
                {
                    name: "Plánováno",
                    id: ISSUE_TYPES.PLANOVANO
                },
                {
                    name: "Otevřeno",
                    id: ISSUE_TYPES.OTEVRENO
                },
                {
                    name: "Uzavřeno",
                    id: ISSUE_TYPES.UZAVRENO
                },
                {
                    name: "Všechny",
                    id: ISSUE_TYPES.VSECHNO
                },
            ]
        }
        else
        {
            this.closeTab(14)
            router.push({ name: 'Maintainers' })
        }
    }
}
</script>
<style scoped>
    @import "../assets/scss/app.scss";
</style>

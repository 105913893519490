import { render, staticRenderFns } from "./MaintainerDetail.vue?vue&type=template&id=058a9127&scoped=true"
import script from "./MaintainerDetail.vue?vue&type=script&lang=js"
export * from "./MaintainerDetail.vue?vue&type=script&lang=js"
import style0 from "./MaintainerDetail.vue?vue&type=style&index=0&id=058a9127&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058a9127",
  null
  
)

export default component.exports